<template>
  <page>
    <template slot="headerLeft">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="订单编号" prop="purchaseNo">
          <el-input placeholder="订单编号" v-model="params.purchaseNo" />
        </el-form-item>

        <el-form-item label="门店名称" prop="merchantName">
          <el-input placeholder="门店名称" v-model="params.merchantName" />
        </el-form-item>

        <el-form-item label="门店分组" prop="groupManageId">
          <el-select v-model="params.groupManageId" clearable>
            <el-option
              v-for="item in groupList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="下单时间" prop="date">
          <el-date-picker
            v-model="params.date"
            type="daterange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="支付方式" prop="payType">
          <el-select v-model="params.payType" clearable>
            <el-option
              v-for="item in payList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </template>

    <template slot="headerRight">
      <el-button
        v-auth="'supplierList:paper'"
        @click="exportOrder"
        type="primary"
      >
        下载报表信息
      </el-button>
    </template>

    <template slot="headerInfo">
      <div class="sumWrap">
        <div class="item">
          <span class="label">总金额：￥</span>
          <span class="value">{{ $utils.formatNum(totalAmount) }}</span>
        </div>
        <div class="item">
          <span class="label">线上付款：￥</span>
          <span class="value">{{ $utils.formatNum(onlineAmount) }}</span>
        </div>
        <div class="item">
          <span class="label">线下付款：￥</span>
          <span class="value">{{ $utils.formatNum(creditAmount) }}</span>
        </div>
        <div class="item">
          <span class="label">余额支付：￥</span>
          <span class="value">{{ $utils.formatNum(balanceAmount) }}</span>
        </div>
      </div>
    </template>

    <el-table
      v-loading="loading"
      :data="tableData"
      border
      height="100%"
      style="width: 100%"
    >
      <el-table-column
        show-overflow-tooltip
        prop="purchaseNo"
        label="订单编号"
      ></el-table-column>

      <el-table-column
        show-overflow-tooltip
        prop="merchantName"
        label="下单门店"
      ></el-table-column>

      <el-table-column show-overflow-tooltip label="实收金额">
        <template slot-scope="{ row }">
          <span>￥{{ $utils.formatNum(row.payPrice) }}</span>
        </template>
      </el-table-column>

      <el-table-column show-overflow-tooltip prop="payTypeStr" label="支付方式">
      </el-table-column>

      <el-table-column
        show-overflow-tooltip
        prop="orderTime"
        label="下单时间"
      ></el-table-column>

      <el-table-column show-overflow-tooltip label="状态">
        <template>
          <span>已完结</span>
        </template>
      </el-table-column>

      <el-table-column
        show-overflow-tooltip
        prop="storeAddress"
        label="所属区域"
      ></el-table-column>
    </el-table>
    <pagination
      slot="pagination"
      :totalCount="totalCount"
      :page-size.sync="params.limit"
      :page.sync="params.page"
      @change="getData"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'

export default {
  mixins: [watchParamsGetData],
  data() {
    return {
      totalAmount: 0,
      onlineAmount: 0,
      creditAmount: 0,
      balanceAmount: 0,
      loading: false,
      tableData: [],
      groupList: [],
      statusObj: {
        1: '未开始',
        2: '进行中',
        3: '已完结',
        4: '已终止'
      },

      payTypeObj: {
        0: '扫码支付',
        1: '工行卡支付',
        2: '线下支付',
        3: '余额支付'
      },

      payList: [
        {
          label: '扫码支付',
          value: 0
        },
        {
          label: '工行卡支付',
          value: 1
        },
        {
          label: '线下支付',
          value: 2
        },
        {
          label: '余额支付',
          value: 3
        }
      ],

      params: {
        date: [],
        status: 4,
        limit: 20,
        page: 1
      },
      totalCount: 0
    }
  },
  created() {
    this.getGroupList()
    this.getData()
  },
  methods: {
    async getAmountInfo(params) {
      const { limit, page, ...sendData } = params
      try {
        const res = await this.$api.reportManagement.getAmountInfo(sendData)

        if (res) {
          this.totalAmount = res.totalAmount
          this.onlineAmount = res.onlineAmount
          this.creditAmount = res.offlineAmount
          this.balanceAmount = res.balance
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getGroupList() {
      try {
        const res = await this.$api.common.getGroupAllList()
        this.groupList = (res.groupManages || []).map(item => {
          return {
            label: item.name,
            value: item.id
          }
        })
      } catch (err) {}
    },
    async exportOrder() {
      try {
        this.loading = true

        const params = { ...this.params }

        const { limit, page, ...sendData } = params

        await this.$api.reportManagement.exportPurchaseOrder(sendData)
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    async getData(query) {
      this.loading = true
      let params = { ...this.params }
      if (query) {
        params.page = 1
        params.limit = 20
      }
      if (params.date && params.date.length) {
        params.startTime = this.$utils.dateFormat(params.date[0], 'YYYY-MM-DD')
        params.endTime = this.$utils.dateFormat(params.date[1], 'YYYY-MM-DD')
      } else {
        params.startTime = undefined
        params.endTime = undefined
      }
      const { date, ...sendData } = params
      try {
        const r = await this.$api.reportManagement.getPurchaseOrderList(
          sendData
        )
        const tableData = r.page.list.map(item => {
          item.payTypeStr = this.payTypeObj[+item.payType]

          //           item.orderTimeStr = this.$utils.dateFormat(
          //   item.orderTime,
          //   'YYYY-MM-DD'
          // )

          return item
        })
        this.tableData = tableData
        this.totalCount = r.page.totalCount
        this.getAmountInfo(sendData)
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.sumWrap {
  display: flex;
  align-items: center;
  font-weight: bold;
  .item {
    margin-right: 50px;
  }
}
</style>
